import { Alert } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';

import './App.css';
import { ClassPage } from './ClassPage';
import { events } from './utils/events';

function App() {
  const [notifications, setNotifications] = useState<any[]>([]);

  useEffect(() => {
    events.on('error', displayErrorMessage);
    events.on('notification', displayNotificationMessage);
  });

  const displayErrorMessage = (e: any) => {
    let updateNotifications = notifications.concat([
      { ...e, type: 'error', severity: 'error' },
    ]);
    setNotifications(updateNotifications);
  };

  const removeNotification = (error: any) => {
    const updateNotifications = notifications.filter((n) => n !== error);
    setNotifications(updateNotifications);
  };

  const displayNotificationMessage = (e: any) => {
    let updateNotifications = notifications.concat([
      { ...e, severity: 'success', type: 'notification' },
    ]);
    setNotifications(updateNotifications);
  };

  return (
    <Router>
      <div className="container">
        <div className="notifications">
          {notifications.length > 0 &&
            notifications.map((notification, index) => {
              return (
                <Alert
                  key={`notification-${index}`}
                  className="alert"
                  onClose={(e) => removeNotification(notification)}
                  severity={notification.severity}
                >
                  {notification.message}
                </Alert>
              );
            })}
        </div>
        <Routes>
          <Route path="/class" element={<ClassPage />} />
          <Route path="*" element={<RedirectPage />} />
        </Routes>
      </div>
    </Router>
  );
}

class RedirectPage extends React.Component {
  componentDidMount() {
    window.location.replace('/class');
  }
  render() {
    return null;
  }
}

export default App;
