import axios, { AxiosInstance } from 'axios';
import winston from 'winston';
import { createLogger, format, transports } from 'winston';

const logger = createLogger({
  level: 'info',
  transports: new transports.Console({
    format: format.simple(),
  }),
});

interface UserProfile {
  firstName: string;
  lastName: string;
  email?: string;
  groupId: number;
  branchId: number;
}

interface TalentLMSAPI {
  getUser(id: string): Promise<void>;
  createNewUser(user: UserProfile): Promise<void>;
  getBranchById(id: string): Promise<any>;
  getGroups(): Promise<any>;
  getBranches(): Promise<any>;
}

enum TalentLMSEndpoints {
  USER_SIGNUP = '/usersignup',
  USER_ID = '/users/id:',
  USER_EMAIL = '/users/email:',
  USER_USERNAME = '/users/username:',
  GROUP_ID = '/groups/id:',
  GROUP_CREATE = '/creategroup',
  BRANCH_ID = '/branches/id:',
  BRANCH_ALL = '/branches',
  GROUP_ALL = '/groups',
  GROUP_USER_ADD = '/addusertogroup'
}

export class TalentLmsClient implements TalentLMSAPI {
  private api: AxiosInstance;
  private log: winston.Logger;

  constructor() {
    this.log = logger.child({ class: 'TalentLMSClient' });
    this.api = axios.create({
      baseURL: 'https://2ubqxzr8n6.execute-api.us-east-1.amazonaws.com/', 
      headers: {
        'X-API-KEY': 'IrJd6v44oB5QfKVOLptGU6HrE3qG2aaf1bMPLqZJ',
      }
    });
  }

  async createNewUser(user: Partial<UserProfile>): Promise<any> {
    const email = this.generateUserEmail(user);
    const username = `${user.firstName}.${user.lastName}`.trim().replace(/\s/g, '').toLowerCase();
    try {
      const res = await this.api.post('development',
        {
          method: 'POST',
          endpoint: TalentLMSEndpoints.USER_SIGNUP,
          data: {
            first_name: user.firstName,
            last_name: user.lastName,
            branch_id: user.branchId,
            group_id: user.groupId,
            password: `norm`,
            login: username,
            email,
          }
      });
      return res;
    } catch (err: any) {
      this.log.error('Failed to create user', err.message);
      throw err;
    }
  }

  async getUser(username: string): Promise<any> {
    try {
      const res = await this.api.post('development', {
        method: 'GET',
        endpoint: `${TalentLMSEndpoints.USER_USERNAME}${username}`,
      });
      return res;
    } catch (err: any) {
      this.log.error('Failed to get user.', err.message);
      throw err;
    }
  }

  async getBranchById(id: string): Promise<any> {
    try {
      const res = await this.api.post('development', {
        method: 'GET',
        endpoint: `${TalentLMSEndpoints.BRANCH_ID}${id}`,
      });
      return res;
    } catch (err: any) {
      this.log.error('Failed to retrieve branch', err.message);
      throw err;
    }
  }
  
  async getBranches(): Promise<any> {
    try {
      const { data } = await this.api.post('development', {
        method: 'GET',
        endpoint: `${TalentLMSEndpoints.BRANCH_ALL}`,
      });
      return data;
    } catch (err: any) {
      this.log.error('Failed to retrieve branches', err.message);
      throw err;
    }
  }

  async getGroups(): Promise<any> {
    
    try {
      const { data } = await this.api.post('development', {
        method: 'GET',
        endpoint: `${TalentLMSEndpoints.GROUP_ALL}`,
      });
      return data;
    } catch (err: any) {
      this.log.error('Failed to retrieve groups', err.message);
      throw err;
    }
  }

  private generateUserEmail(user: Partial<UserProfile>): string {
    const name = `${user.firstName}${
      user.lastName
    }${new Date().getFullYear()}`.trim().replace(/\s/g, '').toLowerCase();
    return `students+${name}@colorsofenglish.com`;
  }
}

const TalentLMSApi = new TalentLmsClient();

export {
  TalentLMSApi
}
