import { Box, Button, CircularProgress, TextField, Select, MenuItem } from '@mui/material';
import { useEffect, useState } from 'react';

import { TalentLMSApi } from './apis';
import { events } from './utils';

export function ClassPage() {
  const { innerWidth } = window;
  const [branchId, setBranchId] = useState('');
  const [branches, setBranches] = useState([]);
  const [groups, setGroups] = useState([]);
  const [groupId, setGroupId] = useState('');
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [rawUsers, setRawUsers] = useState('');

  useEffect(() => {
    async function fetchBranches(){
      const branches = await TalentLMSApi.getBranches();
      setBranches(branches)
    };

    async function fetchGroups() {
      const groups = await TalentLMSApi.getGroups();
      setGroups(groups)
    }
    
    fetchBranches();
    fetchGroups();
  }, [])
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    validateAndCreateUsers();
    try {
      const results = await Promise.allSettled(
        users.map((user) => {
          const fullName = user.split(' ');
          const firstName = fullName[0].trim();
          const lastName = fullName.slice(1).join(' ');
          return TalentLMSApi.createNewUser({
            firstName,
            lastName,
            branchId,
            groupId
          });
        }),
      );
      const successes = results.filter(r => r.status === 'fulfilled');
      const failures = results.filter(r => r.status !== 'fulfilled');
      
      if (successes.length) {
        console.log('Successfully created user...')
        events.emit('notification', {
          message: `Successfully created ${successes.length} new users`,
        });
      }

      if (failures.length){
          events.emit('error', {
            message: `Failed to create ${failures.length} users!`,
        })
      }
    } catch (err) {
      events.emit('error', {
        message: err.message,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleAccountsChange = (e) => {
    setRawUsers(e.target.value);
    validateAndCreateUsers();
  };

  const validateAndCreateUsers = async () => {
    const nameRegex = new RegExp(/^[^\s]+( [^\s]+)+$/);
    const users = rawUsers
      .trim()
      .split(`\n`)
      .map((user) => user.trim())
      .filter((user) => user && nameRegex.test(user));
    setUsers(users);
  };

  const submitDisabled = () => {
    return Boolean(users.length < 1 || branchId.length < 1);
  };

  return (
    <div className="form">
      <img
        src={`https://images.squarespace-cdn.com/content/v1/5ff352e2813d5b357a16f0fe/1609782084838-QMC9PV1YV6D1OJ97V6XR/image-asset.png?format=${
          innerWidth / 3
        }w`}
        alt="The Colors of English, LLC"
      />
      <Box sx={{ background: 'white', width: '35%', margin: 'auto' }}>
        <h1>Account Setup</h1>
        {!loading ? (
          <form onSubmit={handleSubmit} className="form">
            <Select
              value={branchId}
              label="Branch"
              onChange={(e) => setBranchId(e.target.value)}
              style={{ width: '60%' }}
            >
            {
              branches.length && branches.map((branch) => <MenuItem key={branch.id} value={branch.id}>{branch.name}</MenuItem>)
            }
            </Select>
            <Select
              value={groupId}
              label="Group"
              onChange={(e) => setGroupId(e.target.value)}
              style={{ width: '60%' }}
            >
            {
              groups.length && groups.map((group) => <MenuItem key={group.id} value={group.id}>{group.name}</MenuItem>)
            }
            </Select>
            <TextField
              placeholder={`Billy Turner\nJose Alvarez\nWill Smith\nEmily Cartwright\n...`}
              multiline
              minRows={10}
              maxRows={50}
              style={{ width: '60%' }}
              value={rawUsers}
              onChange={handleAccountsChange}
            />
            <span>Total Students: {users.length}</span>
            <div>
              <Button
                type="submit"
                variant="contained"
                disabled={submitDisabled()}
                color="primary"
              >
                Create Accounts
              </Button>
            </div>
          </form>
        ) : (
          <CircularProgress/>
        )}
      </Box>
    </div>
  );
}
